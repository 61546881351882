import React from "react"

import { Breadcrum, SEO } from "../../components/index"

class LinksPage extends React.PureComponent {
  breadcrumPages = [
    {
      text: 'Links',
      to: '/links/',
      active: true
    }
  ];

  render () {
    const title = "Links";
    return (
      <>
        <SEO
          title={title}
          description="Everything you've ever want to ask about Magic: the Gathering (MTG) token cards. Frequently Asked Questions (FAQ) all about MTG Token cards."
          keywords={['faq', 'Frequently Asked Questions', 'facts']}
          pageUrl='/faq/'
        />
        <Breadcrum pages={this.breadcrumPages} />
        <h1>{title}</h1>
        <p>Links to some of our friends and projects in the Magic community:</p>
          <ul>
            <li><strong><a href="https://andrewgioia.github.io/Keyrune/icons.html">Keyrune</a></strong> - Complete Magic set symbol font icons.</li>
            <li>
              <strong><a href="https://budgetmodernmagic.com" title="Budget Modern Magic">Budget Modern Magic</a></strong> - Find MTG decks on MTGO under $100. 
            </li>
            <li>
              <strong><a href="https://www.twitch.tv/daviusminimus">Daviusminimus Twitch</a></strong> - We are proud to sponsor David's twitch channel, check out his Budget Modern Magic videos and watch him play in the <a href="https://www.modernstreamersleague.com/">Modern streamers league</a> every Monday and Wednesday.
            </li>
          </ul>
      </>
    )
  }
}

export default LinksPage
